<template>
  <v-card>
    <v-card-title>Categorias de notícias</v-card-title>

    <v-data-table :headers="headers" :items="newsCategories" disable-pagination hide-default-footer :loading="loading">
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn rounded small color="success" @click="$router.push('/noticias/categorias/cadastrar')">Cadastrar +</v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn class="mr-2" title="Editar perfil" @click="editCategory(item.id)" small icon>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn class="mr-2" title="Deletar perfil" @click="deleteCategory(item.id)" small icon>
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
export default {
  data: () => ({
    headers: [
      { text: "#ID", value: "id" },
      { text: "Nome", value: "name" },
      { text: "Ações", value: "actions" },
    ],
    loading: false,
    newsCategories: [],
    resultsPerPage: 15,
    totalRows: 0,
    filters: {
      currentPage: 1,
    },
  }),
  methods: {
    async lonewsCategories() {
      try {
        this.loading = true;

        let url = `/news-categories`;

        const response = await this.$axios.get(url);

        this.setCategories(response.data);
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    setCategories(data) {
      const cpData = [...data];
      this.newsCategories = cpData;
    },
    async deleteCategory(id) {
      try {
        const confirm = await this.$root.$confirm("Atenção", `Deseja realmente deletar a categoria com o id n. ${id} ?`);

        if (!confirm) return;

        this.loading = true;

        let url = `/news-categories/${id}`;

        await this.$axios.delete(url);

        this.deleteCategoryFromTable(id);

        this.$toast.success("Dados deletados com sucesso");
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    deleteCategoryFromTable(id) {
      const index = this.newsCategories.findIndex((p) => p.id === id);

      this.newsCategories.splice(index, 1);
    },
    editCategory(id) {
      this.$router.push(`/noticias/categorias/${id}`);
    },
  },
  computed: {},
  created() {
    this.lonewsCategories();
  },
};
</script>

<style></style>
